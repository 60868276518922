.text {
  max-width: calc(28rem + (var(--padding) * 2));
  padding: 0 var(--padding);

  p,
  ol,
  li,
  pre,
  table {
    font-family: var(--text-font-family);
  }

  ol {
    list-style: decimal outside;
    margin-left: 1em;
  }

  ul {
    list-style: disc outside;
    margin-left: 1em;
  }

  & + & {
    margin-top: calc(var(--vpadding) * -1);

    p:first-child,
    h4:first-child,
    h3:first-child,
    h2:first-child,
    h5:first-child {
      padding-top: 1.5rem;
    }
  }

  h3 + h6 {
    margin-top: -1em;
  }

  h5,
  h6 {
    padding-bottom: 0.35em;
  }

  // &:not(:last-child) {
  //   h4:only-child, h5:only-child, h6:only-child {
  //     margin-bottom: -4em;
  //   }
  // }

  table {
    width: 100%;
    border: 1px solid #eee;

    th {
      font-weight: bold;
    }

    th,
    td {
      padding: 0.5em 1em;
      border-right: 1px solid #eee;
    }

    tr {
      border-bottom: 1px solid #eee;
    }
  }

  &--image {
    h6 {
      margin-bottom: var(--padding);
    }
  }

  // @at-root .intro:first-child + & p:first-child:first-letter, .intro:first-child + .embed + & p:first-child:first-letter, .intro:first-child + .image + & p:first-child:first-letter {
  //   @at-root .article-content--dropcap & {
  //     float: left;
  //     font-family: var(--title-font-family);
  //     font-size: 3em;
  //     display: inline-block;
  //     margin-top: .825rem;
  //     margin-right: .6rem;
  //   }

  //   @at-root .article-content--dropcap__sans & {
  //     font-family: var(--font-family);
  //     font-weight: bold;
  //     margin-top: .8rem;
  //     font-size: 2.95em;
  //   }
  //   @at-root .article-content--dropcap__square & {
  //     color: var(--white);
  //     background: var(--black);
  //     padding: .45em .375em;
  //     font-size: 2.6em;
  //     margin-top: .4rem;
  //     margin-right: .6rem;
  //     min-width: 3em;
  //     font-variant-numeric: lining-nums;

  //     @at-root .darkmode {
  //       color: var(--darkmode);
  //       background: var(--white);
  //     }
  //   }
  // }
}

.slides {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  padding: var(--spadding);
  margin: 0;
  background: #f0f0f0;
}
