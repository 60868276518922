.share {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 0;
  color: grey;
  padding: var(--vpadding) var(--padding);
  overflow: hidden;

  .new {
    display: none;
  }

  .items {
    margin: 0;
    bottom: 0;
    &:before {
      content: attr(data-label);
      color: grey;
    }
  }

  .item {
    position: relative;
    width: 0.9em;
    height: 0.9em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-left: 0.5em;
    cursor: pointer;
    transition: opacity var(--speed) var(--ease);

    &Twitter,
    &Facebook,
    &Linkedin {
      opacity: 0.8;
    }

    &Copy {
      opacity: 0.9;
    }

    &:hover {
      opacity: 1;
    }

    svg {
      width: 1em;

      path {
        fill: currentColor;
      }

      g {
        stroke: currentColor;

        path {
          fill: none;
        }
      }
    }

    &Message {
      display: block;
      position: absolute;
      left: 50%;
      bottom: 100%;
      white-space: nowrap;
      font-size: 0.75em;
      padding: 0.5em 1em 0.5em;
      border: 1px solid #f2f2f2;
      background: var(--white);
      font-weight: 500;
      margin-bottom: 0.75em;
      transform: translate3d(-50%, 0.75em, 0);
      border-radius: 0.5em;
      transition:
        opacity var(--speed) var(--ease),
        transform var(--speed) var(--speed) var(--ease);
      opacity: 0;
      pointer-events: none;

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top: 6px solid #f2f2f2;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top: 5px solid #fff;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }

    &Copied .itemMessage {
      transition:
        opacity var(--speed) var(--ease),
        transform var(--speed) var(--ease);
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  span[class*='Button_button'] {
    box-shadow: none;
  }
}
