@import 'ui/styles/utilities';

.container {
  position: absolute;
  top: var(--nav-height);
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
  font-size: var(--font-size);
  font-weight: 500;
  text-align: center;
  width: 100%;
  pointer-events: none;
  opacity: 0.2;
}
