.button {
  position: relative;
  display: inline-block;
  background: var(--color, var(--highlight));
  font-family: var(--font-family);
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.8em 1.35em 0.85em;
  border-radius: 2em;
  color: var(--white, #fff);
  mask-image: -webkit-radial-gradient(white, black);
  white-space: nowrap;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white, #fff);
    opacity: 0;
    transition: opacity var(--speed) var(--ease);
  }

  &:hover:before {
    opacity: 0.1;
  }

  span {
    position: relative;
  }
}

.diap {
  color: var(--black, #000);

  &:before {
    background: var(--black, #000);
  }
}

.outline {
  background: none;
  box-shadow: inset 0 0 0 1.5px currentColor;
  color: inherit;

  svg {
    position: relative;
    top: -0.1em;
    width: 0.9em;
    height: 0.9em;
    vertical-align: middle;
    margin-left: 0.5em;

    g {
      stroke: rgba(124, 124, 124, 0.9);
    }
  }
}

.arrow {
  span {
    &:after {
      content: '↗';
      position: relative;
      top: 0.1em;
      display: inline-block;
      margin-left: 0.25em;
      margin-right: -0.1em;
      font-weight: normal;
      font-size: 1.3em;
      line-height: 0.9rem;
    }
  }
}
