.embed {
  max-width: calc(28rem + (var(--padding) * 2));
  padding: 0 var(--padding);

  &Figure {
    position: relative;
    aspect-ratio: 16/9;

    @supports (aspect-ratio: 16/9) {
      &Spacer {
        display: none;
      }
    }
  }

  iframe {
    display: block;
  }
}

.provider {
  &Coverstories iframe {
    position: relative !important;
  }

  &Spotify .embedFigure {
    aspect-ratio: auto !important;
  }

  &Spotify iframe {
    width: 100% !important;
    border-radius: 0.35em;
  }
}

.video {
  padding: 0 !important;
  max-width: calc(28rem + (var(--padding) * 6));
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slides {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  background: var(--darkmode);

  .img,
  .img span {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: var(--spadding);
    color: #fff;
    margin: 0;
    max-width: none;
  }
}

.full {
  padding: 0;
}

.editor {
  background: #fff;

  &:before {
    background: var(--black);
    opacity: 0.05;
  }
}
