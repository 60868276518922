@import 'ui/styles/utilities';

.container {
  width: 24rem;
  padding: calc(var(--padding) / 2) calc(var(--padding) / 2) var(--padding);
  max-height: calc(
    100vh - var(--navbar-height) - var(--padding) - (var(--padding) * 0.666)
  );
  overflow: auto;
  background: var(--white);
  border-radius: 18px;
  position: fixed;
  top: calc(var(--navbar-height) + var(--padding));
  right: calc(var(--padding) * 0.666);
  box-shadow:
    0 0 1px 0 rgba(#000, 0.1),
    0 0 6px 0 rgba(#000, 0.025);
}
