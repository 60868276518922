@import 'ui/styles/utilities';

.container {
  position: relative;
}

.action {
  position: relative;
  margin: calc(var(--padding) / 2) calc(var(--padding) / -2)
    calc(var(--padding) / 4);
  padding: calc(var(--padding) * 0.85) calc(var(--padding) * 0.75);
  background-color: var(--background, #070707);
  background-image: var(--image, none);
  background-size: cover;
  color: var(--text, #fff);
  border-radius: calc(var(--border-radius) * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: color var(--speed) var(--ease);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background, #070707);
    opacity: 0.65;
    border-radius: calc(var(--border-radius) * 1.5);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, var(--background) 50%, transparent);
    max-width: calc(22em + (var(--padding) * 1.5));
    opacity: 0.65;
    border-radius: calc(var(--border-radius) * 1.5);
  }

  // &.light {
  //   color: #000;
  // }

  form {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 23em;

    input,
    textarea {
      @include placeholder {
        color: rgba(#fff, 0.3);
      }
    }
  }
}

.title {
  position: relative;

  div[data-block] {
    font-size: 1.6em;
    line-height: 1.1em;
    margin-bottom: 0.75em;
    white-space: pre-wrap;
    font-weight: 500;
  }
}

.text {
  position: relative;

  div[data-block] {
    font-size: 1em;
    line-height: 1.5em;
    white-space: pre-wrap;
  }
}

.settings {
  position: absolute;
  top: 50%;
  transform: translate3d(-0.25em, -50%, 0);
  left: calc(100% + var(--padding));
  background: var(--white);
  border: 1px solid var(--dark05);
  border-radius: calc(var(--border-radius) * 1.5);
  padding: calc(var(--padding) / 2.5);
  width: calc((100vw - 32rem - var(--padding)) / 2);
  max-width: 15em;
  opacity: 0;
  pointer-events: none;
  transition: opacity calc(var(--speed) / 2) var(--ease);
  transition-property: transform, opacity;
  color: var(--dark);

  @at-root .show & {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, -50%, 0);
  }

  @at-root [data-color-picker='open'] & {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, -50%, 0);
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: 100%;
    width: 0;
    height: 0;
    border: 0.4rem solid transparent;
    border-right-color: var(--dark05);
  }

  &:after {
    border: 0.35rem solid transparent;
    border-right-color: var(--white);
  }
}

.button {
  position: relative;
  z-index: 2;
  background: var(--color, $highlight);
  border-radius: 3em;
  margin-top: 1.5rem;
  color: #fff;
  transition: color var(--speed) var(--ease);

  &.light {
    color: #000;
  }

  form {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-wrap {
    font-size: var(--font-size);
  }

  &-preview {
    font-weight: 500;
    padding: 0.65em 1.1em;
    font-size: 1.15em;

    span {
      opacity: 0;

      @at-root .placeholder & {
        opacity: 0.4;
      }
    }
  }

  &-text {
    padding: 0.55em 1.1em;
    font-size: 1.15em;
  }

  &-link {
    background: rgba(#fff, 0.85);
    backdrop-filter: blur(0.6em);
    color: var(--dark);
    padding: 0.5em 1em 0.6em;
    border-radius: var(--border-radius);
    transition: opacity var(--speed) var(--ease);
    transition-property: transform, opacity;
    transform: translate3d(0, -0.25em, 0);
    opacity: 0;
    pointer-events: none;
    max-width: 25.5rem;
    min-width: 11.2em;
    height: 2.6em;

    @at-root .show & {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 0);
    }

    &:after {
      content: attr(data-value);
      opacity: 0;
    }

    i {
      position: absolute;
      bottom: 100%;
      left: 1.5em;
      width: 1.2em;
      height: 0.6em;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.2em;
        height: 1.2em;
        background: rgba(mix(#fff, $purple, 87.5%), 0.85);
        backdrop-filter: blur(0.6em);
        transform: rotate(45deg);
        transform-origin: 0 50%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--dark05);
      border-radius: var(--border-radius);
    }

    textarea[name],
    input[name] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      padding: 0.5em 1em 0.6em;
      background: transparent;
      border-radius: var(--border-radius);

      @include placeholder {
        color: var(--dark30);
      }
    }
  }
}

.slides {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding-right: 16em;

  .action {
    margin: 0;
    height: min(
      calc(100vh - (var(--navbar-height) * 2) - var(--padding) * 2),
      32rem
    );
    aspect-ratio: 9/16;
    border-radius: var(--border-radius);
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      border-radius: var(--border-radius);
    }

    div[data-block] *,
    textarea {
      text-align: center;
    }
  }

  .button {
    &-link {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(
        (
            min(
                calc(100vh - (var(--navbar-height) * 2) - var(--padding) * 2),
                32rem
              ) / 16 * 9
          ) - 2em
      );

      i {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .settings {
    transform: translate3d(-0.25em, -50%, 0);
    right: 0;
    width: 18em;
    left: auto;
    text-align: left;

    @at-root .show & {
      transform: translate3d(0em, -50%, 0);
    }
  }
}

.video {
  max-width: 100vh;
  margin: 0 auto;

  .action {
    margin-left: 0;
    margin-right: 0;
    padding: calc(var(--padding) * 0.65) calc(var(--padding) * 0.75);
    border-radius: var(--border-radius);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    form {
      max-width: none;
      margin: auto 0;

      div[class*='hidden'] {
        display: none;
      }

      div[data-value] {
        margin: 0;
      }
    }

    &:before,
    &:after {
      border-radius: var(--border-radius);
    }

    .button {
      flex-shrink: 0;
      margin: 0;

      &-link {
        position: absolute;
        right: 0;

        i {
          left: auto;
          right: 1.5em;
        }
      }
    }
  }

  .settings {
    left: calc(100% + (var(--padding) / 4));
    top: 50%;
    width: calc((100vw - 33rem - var(--padding)) / 2);
  }
}
