@import 'ui/styles/utilities';

.container {
  margin: -0.15em 0;

  span[style*='underline'],
  span[style*='underline'] span {
    text-decoration: none !important;
    @include underline(currentColor, 0.05em, 100%);
  }

  a {
    color: inherit;
    @include underline(currentColor, 0.05em, 100%);

    span {
      background: none !important;
      text-decoration: none !important;
    }
  }
}

// .toolbar {

// }

// .editor {

// }
