@use 'sass:math';

@import '../../styles/common';

$factor: math.div(390, 300);

.coverWrapper {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24em;
  height: 20em;
  border: 1px solid #ccc;
  margin: 1em;
}

.cover {
  display: block;
  position: relative;
  font-size: inherit;
  user-select: none;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  backface-visibility: hidden;
  user-select: none;
  font-family: var(--font-family);
  color: #fff;
  width: math.div(20em, $factor);
  height: 20em;
  cursor: pointer;
}

// shadow
.shadow {
  position: absolute;
  border-radius: var(--cover-border-radius, 1em);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow:
    0 0 0.4em rgba(#000, 0.06),
    0 0.4em 2em rgba(#000, 0.24);
  transform: scale(0.975) translate3d(0, 0, 0);
  transition: transform var(--speed) var(--ease);

  @at-root .stripped & {
    display: none;
  }

  @at-root .ad & {
    box-shadow: none;
  }
}

// inside
.inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0) scale(1) rotate(0.0001deg);
  transition: transform var(--speed) var(--ease);
}

@media (hover: none) {
  .cover:not(.passive):active .inside {
    transform: translate3d(0, 0, 0) scale(0.98) rotate(0.0002deg);
  }

  .cover:not(.passive):active .shadow {
    transform: scale(0.95) translate3d(0, 0, 0);
  }
}

@media (hover: hover) {
  .cover:not(.passive):hover .inside {
    transform: translate3d(0, 0, 0) scale(1.025) rotate(0.0001deg);
  }

  .cover:not(.passive):active .inside {
    transform: translate3d(0, 0, 0) scale(1) rotate(0.0002deg);
  }
}

// image
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--cover-border-radius, 1em);
  overflow: hidden;
  mask-image: radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: translate3d(0, 0, 0) rotate(0.00001deg);

  img,
  video,
  &Img {
    object-fit: cover;
    background-size: cover;
    transform: translate3d(0, 0, 0) rotate(-0.00001deg);
    height: 100%;
    width: 100%;
  }

  &Fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0) rotate(-0.00001deg);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;

  @at-root .color--dark & {
    mix-blend-mode: screen;
  }
}

// text
.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  transform: translate3d(0, 0, 2px);
  overflow: hidden;
  padding: 1.3em;
  gap: 1.3em;
}

.textBrand {
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 0.015em;
  height: 2.8em;

  @at-root .minimal & {
    visibility: hidden;
  }

  @at-root .stripped & {
    visibility: hidden;
  }

  @at-root .hideLogo & {
    visibility: hidden;
  }
}

.textLogo {
  position: relative;
  width: 100%;
  max-width: 10em;
  height: 4.5em;
  transform: translate3d(0, 0, 0) rotate(0.01deg);

  @at-root .minimal & {
    visibility: hidden;
  }

  span,
  div {
    overflow: visible !important;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @at-root .stripped & {
    visibility: hidden;
  }
}

.textTitle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  font-weight: bold;
  flex-shrink: 1;

  &Sizer {
    width: 100%;
    display: flex;
    flex-direction: column;

    div {
      height: 100%;
      width: 100%;
    }
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 0.95em;
  }

  em {
    display: flex;
    width: 100%;
    line-height: 0.95em;
    font-size: 0.45em;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    padding-bottom: 0.35em;
    opacity: 0.8;
  }
}

.textGo {
  font-size: 0.8em;
  letter-spacing: 0.015em;
  display: flex;
  height: 2.8em;
  flex-shrink: 0;

  @at-root .minimal & {
    font-size: 0.6em;
  }

  @at-root .stripped & {
    visibility: hidden;
  }

  .icon {
    display: block;
    font-size: 1.5em;
    font-family: 'CSIcons';
    margin-top: auto;

    &:before {
      content: 'E';
    }
  }

  .button {
    display: inline-block;
    padding: 0.65em 0.95em 0.675em;
    font-size: 1.2em;
    line-height: 1em;
    border-radius: 3em;
    background: #fff;
    color: var(--color, #000);
    text-decoration: none;
    transition: background var(--speed) var(--ease);

    span {
      display: inline-block;
      vertical-align: text-top;
    }

    @at-root .type-sans &,
      .type-serif & {
      font-weight: 500;
    }
  }
}

// options
.passive,
.stripped {
  pointer-events: none;
}

.type-serif .textTitle {
  font-size: 1.9em;
  font-family: var(--title-font-family);
  font-weight: bold;

  em {
    font-size: 0.4em;
    letter-spacing: 0.1em;
  }
}

.type-sans .textTitle {
  font-size: 1.75em;
  font-family: var(--font-family);
  font-weight: bold;

  span {
    line-height: 1em;
  }
}

.type-custom .textTitle {
  font-size: 1.9em;
  font-family: var(--custom-title-font-family, var(--font-family));
  font-weight: var(--custom-title-font-weight, 700);
}

.type-custom .textBrand,
.type-custom .textGo .button {
  font-family: var(--custom-brand-font-family, var(--font-family));
  font-weight: 500;
  font-weight: 600;
}

.align {
  &-left .text,
  &-left .textTitle {
    text-align: left;
    align-items: flex-start;
  }

  &-right .text,
  &-right .textTitle {
    text-align: right;
    align-items: flex-end;
  }
}

.align-left .textLogo img {
  object-position: top left !important;
}

.align-center .textLogo img {
  object-position: top center !important;
}

.align-right .textLogo img {
  object-position: top right !important;
}

.case {
  &-uppercase .textTitle {
    text-transform: uppercase;
  }

  &-titlecase .textTitle {
    text-transform: capitalize;
  }

  &-lowercase .textTitle {
    text-transform: lowercase;
  }
}

.position {
  &-top .textTitle {
    justify-content: flex-start;
  }

  &-bottom .textTitle {
    justify-content: flex-end;
  }
}

.layout {
  &-portrait {
    width: math.div(20em, $factor);
    height: 20em;
  }

  &-square {
    height: 20em;
    width: 20em;

    .inside {
      font-size: 1.15em;
    }
  }

  &-landscape {
    width: 20em;
    height: math.div(20em, $factor);
  }

  &-responsive {
    width: var(--cs__width, 100vw);
    height: var(--cs__height, 100vh);
  }
}

.nomimg {
  transform: none;
}

.noimg .image {
  transform: none;

  img {
    display: none;
  }
}
