@import 'ui/styles/utilities';

.container {
  padding: calc(var(--padding) / 3) calc(var(--padding) / 1.5);
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

.content {
  height: var(--radix-collapse-content-height, auto);
  transition: height var(--speed) var(--ease);
  overflow: hidden;

  &[data-state='closed'] {
    animation: slideUp var(--speed) ease-out;
  }

  &[data-state='open'] {
    animation: slideDown var(--speed) ease-in;
  }
}

.inner {
  padding-top: calc(var(--padding) / 1.5);
}

.label {
  position: relative;
  padding-top: calc(var(--padding) / 2.5);
  padding-bottom: calc(var(--padding) / 3);
  border-bottom: 1px solid var(--dark05);
  font-size: var(--font-size);
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 0.5em;
    height: 0.5em;
    border-right: 1.5px solid var(--dark15);
    border-bottom: 1.5px solid var(--dark15);
    transition: transform var(--speed) var(--ease);
    transform: translateY(-50%) rotate(45deg);
  }

  &[data-state='closed'] {
    &:after {
      transform: translateY(-50%) rotate(135deg);
    }
  }
}
