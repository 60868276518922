.video {
  max-width: calc(28rem + (var(--padding) * 6));
}

.figure {
  position: relative;

  @supports (aspect-ratio: 16/9) {
    aspect-ratio: 16/9;

    .spacer {
      display: none;
    }
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mute {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  background: #fff;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  border: 0;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  padding: 0.35em 0.5em;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 0.2em 0.7em rgba(#000, 0.1);
  transition: opacity var(--speed) var(--ease);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0;
    transition: opacity var(--speed) var(--ease);
  }

  svg {
    width: 1.1em;
    height: 1.1em;
    vertical-align: middle;
    stroke: currentColor;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    margin-right: 0.4em;
    margin-top: -0.05em;
    margin-bottom: -0.05em;
    fill: none;

    polygon {
      fill: currentColor;
    }
  }

  &:hover:after {
    opacity: 0.1;
  }

  span {
    font-size: 0.9em;
  }

  &.muted {
    opacity: 1;
    pointer-events: all;
  }
}

// modes

.full {
  max-width: none;
  padding: 0;
  margin: 0;
}

.slides {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  background: var(--darkmode);

  .figure {
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: var(--spadding);
    color: #fff;
    margin: 0;
    max-width: none;
  }
}

.agnoplay {
  iframe {
    width: 100%;
    height: 100%;
  }
}
