@import 'ui/styles/utilities';

.container {
  margin: 0 var(--padding) calc(var(--padding) / 2);
  border-radius: 1.2em;
  border: 1px solid var(--dark05);
  padding: var(--padding);

  @include md {
    display: flex;
  }
}

.label {
  font-size: 0.95em;
  font-weight: 500;
  width: 25%;
  flex-shrink: 0;
  margin-top: -0.1em;

  @include smmax {
    width: 100%;
    margin-bottom: var(--padding);
  }

  h6 {
    font-weight: normal;
    font-size: calc(var(--font-size) * 1.05);
    color: var(--dark50);
  }
}

.children {
  width: 100%;

  form {
    padding: 0;

    & > div {
      border-bottom: 1px solid var(--dark05);
      padding-bottom: calc(var(--padding) * 0.65);
      padding-top: calc(var(--padding) * 0.75);
      margin-bottom: 0 !important;
    }

    &:first-child {
      & > div {
        &:first-child {
          padding-top: 0;
        }
      }
    }

    &:last-child {
      & > div {
        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}

.large {
  flex-direction: column;
}

.large .children {
  padding-top: calc(var(--padding) * 0.75);
}
