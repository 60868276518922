.image {
  max-width: calc(28rem + (var(--padding) * 6));
  padding: 0;

  &-slider {
    overflow: visible;

    &-container {
      overflow: hidden;
    }

    &-pagination {
      bottom: calc(var(--padding) * 0.5) !important;

      @media (min-width: 900px) {
        bottom: calc(var(--padding) * 0.75) !important;
      }

      .swiper-pagination-bullet {
        background: var(--white, #fff);
        opacity: 0.5;

        &-active {
          opacity: 1;
          background: var(--color, var(--highlight));
        }
      }
    }
  }
}

.img {
  > img {
    width: 100%;
    display: block;
    height: auto;
    object-position: calc(var(--left, 0) * 100%) calc(var(--top, 0) * 100%);
  }

  & + & {
    margin-top: var(--padding);
  }
}

.crop {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(100% / var(--ratio));
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      height: 100% !important;
      object-fit: cover;
    }
  }
}

.slides {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: none;
  background: var(--darkmode);

  img {
    object-fit: cover;
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: var(--spadding);
    color: #fff;
    margin: 0;
    max-width: none;
  }
}
