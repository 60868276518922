.toolbar {
  top: 0;
  left: 50%;
  transform: translate(-50%) scale(0);
  margin-top: -8px;
  position: absolute;
  background: rgba(#fff, 0.75);
  padding: 0.3em;
  border-radius: var(--border-radius);
  z-index: 2;
  box-sizing: border-box;
  backdrop-filter: blur(0.6em);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark05);
    pointer-events: none;
  }

  input {
    padding: 4px 10px 6px;
    height: 34px;
    font-size: var(--font-size);
    width: 15em;
  }
}

.buttonWrapper {
  display: inline-block;
  margin: 0 0.15em;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  + div:not(.buttonWrapper) {
    position: relative;
    margin: 0 0.15em;
    border-right: 0;

    &:before {
      content: '';
      position: absolute;
      top: -1em;
      right: 0;
      width: 1px;
      height: 300%;
      background-color: var(--white);
    }
  }
}

.button {
  background: transparent;
  color: var(--dark50);
  font-size: 15px;
  border: 0;
  // padding-top: 5px;
  vertical-align: bottom;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  width: 32px;
  border-radius: calc(var(--border-radius) / 1.5);
  transition: all var(--speed) var(--ease);

  &:hover,
  &:focus {
    background: var(--dark05);
    outline: 0;
    /* reset for :focus */
  }

  svg {
    fill: currentColor;
    margin-top: 5.5px;
    width: 20px;
    height: 20px;
  }
}

.active {
  background: var(--dark10);
  color: var(--dark);
}
