@import 'ui/styles/utilities';

.container {
  padding: 0 var(--padding) var(--padding);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: calc(var(--padding) / 2);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  display: flex;
}

.item.hidden {
  display: none;
}
