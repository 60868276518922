@import 'ui/styles/utilities';

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--padding);
  font-size: var(--font-size);
  user-select: none;

  > a {
    position: relative;
    z-index: 1;
  }
}

.pdf {
  position: relative;
  // padding: 0;

  // &:after {
  //   left: 0;
  //   right: 0;
  // }
}

.buttons {
  margin-left: auto;

  &:empty {
    display: none;
  }

  a {
    &:last-child {
      margin-right: 0;
    }
  }
}

.container h2 {
  position: relative;
  font-size: 1.1rem;
  height: 100%;
  display: flex;
  margin-right: 0.85em;
  padding-right: 0.75em;
  align-items: center;
  font-weight: 500;
  color: var(--dark);
  letter-spacing: -0.005em;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   width: 1px;
  //   height: 1em;
  //   transform-origin: center center;
  //   transform: translate3d(0,-50%,0) rotate(10deg);
  //   background: var(--dark15)
  // }
}
