.action {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: calc(var(--border-radius) * 2);
  padding: calc(var(--padding) * 1.4) calc(var(--padding) * 1.5);
  background: var(--bg, #070707);
  color: var(--text, #fff);
  overflow: hidden;
  display: block;
  margin-bottom: 0 !important;

  @media (min-width: 900px) {
    width: calc(100% - var(--padding));
    max-width: calc(28rem + (var(--padding) * 4));
    border-radius: var(--border-radius);
    padding: calc(var(--padding) * 1.9) calc(var(--padding) * 1.75)
      calc(var(--padding) * 1.75);
    box-shadow:
      0 0 0.3em 0 rgba(#000, 0.07),
      0 0.4em 1.4em 0 rgba(#000, 0.1);
  }

  &.light {
    color: var(--text, #000);
  }

  &.dark:not(.color) {
    background: var(--darkmode);
    color: var(--white);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .content {
    position: relative;
    z-index: 2;
  }

  .text {
    position: relative;

    h3 {
      max-width: 11em;
    }

    p {
      max-width: 20em;
    }

    @media (max-width: 900px) {
      font-size: 0.9em;
    }
  }

  .links {
    margin: var(--padding) -0.4em 0;

    @media (min-width: 900px) {
      margin: calc(var(--padding) * 1.5) -0.4em 0;
    }
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.65;
  }

  &:after {
    background: var(--bg, #070707);
  }

  &:before {
    background: linear-gradient(to right, var(--bg, #070707) 50%, transparent);
    max-width: calc(22em + (var(--padding) * 2));
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.light .image {
  &:after {
    background: var(--bg, #fff);
  }

  &:before {
    background: linear-gradient(to right, var(--bg, #fff) 50%, transparent);
    max-width: calc(22em + (var(--padding) * 2));
  }
}

.slides {
  margin: 0 !important;
  height: 100%;
  width: 100%;
  padding: var(--spadding);
  max-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;

  .content {
    width: 100%;
    max-width: 20em;
  }

  .float,
  & + .float {
    display: none;
  }
}

.inline {
  margin: 0 auto !important;
  max-width: none;
  width: 100%;
  padding: calc(var(--padding));

  @media (min-width: 900px) {
    padding: calc(var(--padding) * 1.25) calc(var(--padding) * 1.5);

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .links {
      flex-shrink: 0;
      margin: 0;
    }
  }

  .text {
    width: 100%;

    @media (min-width: 900px) {
      padding-right: var(--padding);
      font-size: 0.85em;
    }

    p {
      max-width: 28em;
      display: none;
    }

    h3 {
      margin-top: -0.1em;
      margin-bottom: 0;
      max-width: 28em;

      br {
        display: none;
      }

      @media (max-width: 900px) {
        font-size: 1.5em;
        max-width: none !important;
      }
    }
  }
}
