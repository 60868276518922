@import 'ui/styles/utilities';

.container {
}

.inside {
  margin: calc(var(--padding) / 2) auto;
  max-width: 36em;
}

.block {
  position: relative;
  border-radius: var(--border-radius);

  &.isDragging {
    border-radius: var(--border-radius);
    background: var(--white);
    box-shadow:
      0 0 0.15em var(--dark05),
      0 0.5em 1em var(--dark05);
    z-index: 3;
  }

  &:hover {
    z-index: 2;
  }
}

.block-inside {
  position: relative;
  padding: calc(var(--padding) / 2) calc(var(--padding) * 2)
    calc(var(--padding) / 2) calc(var(--padding) * 1.5);
  margin-left: calc(var(--padding) / 2);
  box-shadow: none;
  min-height: calc(var(--padding) + 1.2rem);
}

.isDragging .block {
  box-shadow:
    0 0 0.15em var(--dark05),
    0 0.5em 1em var(--dark05);
}

.block-actions {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  display: flex;
  opacity: 0;
  transition: opacity var(--speed) var(--ease);
}

@at-root .dragging .block-actions,
  .isDragging .block-actions,
  .block:hover .block-actions {
  opacity: 1;
}

.block-action {
  position: relative;
  pointer-events: all;
  z-index: 10;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  color: currentColor;
  background: transparent;
  transition: all var(--speed) var(--ease);
  cursor: pointer;
  backdrop-filter: blur(0.6em);
  transform: translate3d(0, 0, 0);

  &:before,
  &:after {
    content: '';
    opacity: 0.2;
    transition: opacity var(--speed) var(--ease);
  }

  &:hover {
    background: var(--dark05);

    &:before,
    &:after {
      opacity: 0.4;
    }
  }
}

.block-handle {
  margin-right: auto;
  cursor: grab;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.275em;
    height: 0.275em;
    border-top: 1.5px solid currentColor;
    border-right: 1.5px solid currentColor;
  }

  &:before {
    transform: translate(-40%, -100%) rotate(-45deg);
  }

  &:after {
    transform: translate(-40%, 00%) rotate(135deg);
  }
}

.block-delete {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    border: 1.5px solid currentColor;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 51%;
    height: 1.5px;
    background: currentColor;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.block-duplicate {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 50%;
    border-radius: 2px;
    border: 1.5px solid currentColor;
    transform: translate(-62.5%, -60%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 50%;
    border-radius: 0 0 4px 0;
    border-right: 1.5px solid currentColor;
    border-bottom: 1.5px solid currentColor;
    transform: translate(-37.5%, -40%);
  }
}

.slides {
  overflow-x: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .inside {
    display: flex;
    gap: calc(var(--padding) / 2);
    align-items: center;
    padding: 0 calc(var(--padding) * 1.75) var(--navbar-height);
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .block {
    height: min(
      calc(100vh - (var(--navbar-height) * 2) - var(--padding) * 2),
      32rem
    );
    aspect-ratio: 9/16;
    flex-shrink: 0;
    font-size: min(
      (100vh - (var(--navbar-height) * 2) - var(--padding) * 2)/30,
      1.05rem
    );
    background: #f0f0f0;

    &-inside {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

.empty {
  position: relative;
  padding: calc(var(--padding) * 2) 0;
  color: #000;

  & > div {
    position: relative;
    top: 0;
    padding: 0;
  }
}

.empty em {
  position: relative;
  top: 0.05em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 0.45em;
  background: currentColor;
  border-radius: 0.5em;
  opacity: 0.75;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(0.1deg);
    width: 2px;
    height: 55%;
    background: #fff;
    border-radius: 5em;
  }

  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(90deg);
  }
}

.slides .empty {
  align-items: center;
  flex-grow: 1;
  padding: 1em;
  flex-shrink: 0;
  width: calc(
    (100vw - var(--padding) * 2) -
      (
        min(
            calc(100vh - (var(--navbar-height) * 2) - var(--padding) * 2),
            32rem
          ) / 16 * 9
      )
  );
}

.slides .block.video,
.slides .block.embed,
.slides .block.image {
  .block-action {
    color: #fff;

    &:before,
    &:after {
      content: '';
      opacity: 0.4;
      transition: opacity var(--speed) var(--ease);
    }

    &:hover {
      background: rgba(#fff, 0.2);

      &:before,
      &:after {
        opacity: 0.6;
      }
    }
  }
}

// .slides .empty em {
//   width: 1.2em;
//   height: 1.2em;
//   border-radius: .45em;
//   margin: -.05em .2em;

//   &:before,
//   &:after {
//     width: 1.5px;
//   }
// }
