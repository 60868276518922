@import 'ui/styles/utilities';

.container {
  position: relative;
  user-select: none;
}

.crop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.canvas {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  mask-image: radial-gradient(white, black);
  transform: translate3d(0, 0, 0) rotate(0.0001deg);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% * var(--scale, 1));
  transition: opacity var(--speed) var(--ease);
  cursor: grab;
  background-size: cover;
  border-radius: var(--border-radius);

  &--dragging {
    cursor: grabbing;
    transition: none;
  }
}

.drag {
  opacity: 0;
  pointer-events: none;
}

.handle {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  transition: opacity var(--speed) var(--ease);
  transition-property: opacity, color;
  color: mix($purple, $white, 50%);
  z-index: 3;
  cursor: grab;
  pointer-events: all;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 0.4em;
    background: var(--white);
    border: 1.5px solid currentColor;
    transform: translate(-50%, -50%);
    border-radius: 1em;
  }

  &:hover {
    color: var(--highlight);
  }

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }

  &--dragging {
    cursor: grabbing;
  }
}

.container:hover .handle {
  opacity: 1;
}

.canvas:hover + .drag {
  opacity: 0.1;
}

.fixed .handle {
  display: none;
}
