.intro {
  max-width: calc(28rem + (var(--padding) * 2));
  text-align: center;
  padding: 0 var(--padding);

  p {
    font-size: 1.1em;
    line-height: 1.45em;

    @media (min-width: 900px) {
      font-size: 1.2em;
    }
  }
}
