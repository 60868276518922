@keyframes loader {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25em;
  height: 1.25em;
  transform: translate3d(-50%, -50%, 0) rotate(0deg);
  animation: loader 0.65s linear infinite;
  border: 2.5px solid currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 100%;
  opacity: 0.2;
}

.small {
  width: 1em;
  height: 1em;
  border-width: 2px;
}

.medium {
  width: 1.4em;
  height: 1.4em;
  border-width: 2.5px;
}
