@import 'ui/styles/utilities';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  font-size: var(--font-size);
  transform: translate3d(0, 0, 0);
}

.button {
  position: absolute;
  left: calc(var(--padding) / 2);
  top: 50%;
  line-height: 1em;
  width: auto;
  min-width: 2em;
  height: 2em;
  border-radius: 0.7em;
  padding: 0.5em 0.6em 0.4em;
  background: var(--purple);
  transform: translate3d(0, -50%, 0) rotate(0.01deg);
  overflow: hidden;
  cursor: pointer;
  transition: opacity var(--speed) var(--ease);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--dark15);
    opacity: 0;
    transition: opacity var(--speed) var(--ease);
  }

  &:hover:before {
    opacity: 1;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transition: transform var(--speed) var(--ease);
    color: #fff;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(0.1deg);
      width: 0.1rem;
      height: 90%;
      background: currentColor;
      border-radius: 5em;
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }
}

.adding .button i {
  transform: translate3d(-50%, -50%, 0) rotate(135deg);
}

.options {
  position: absolute;
  top: 50%;
  z-index: 9999;
  left: calc(var(--padding) * 1.35);
  transform: translate3d(0, -50%, 0);
  transition:
    transform var(--speed) var(--ease),
    opacity calc(var(--speed) * 0.75) var(--ease);
  opacity: 1;
  font-weight: 500;
  padding: 0.6em;
  border-radius: var(--border-radius);
  color: var(--dark);
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.adding .options {
  pointer-events: all;
}

.option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6em 1em;
  border-radius: 0.5em;
  background: transparent;
  transition: all var(--speed) var(--ease);
  cursor: pointer;
  background: var(--lightrgba);
  text-align: center;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(-0.75em, 0, 0);
  backdrop-filter: blur(1rem);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--purple);
    opacity: 0;
    transition: opacity var(--speed) var(--ease);
  }

  &:not(:last-child) {
    margin-bottom: 0.2em;
  }

  &:hover:before {
    opacity: 0.05;
  }

  .icon {
    position: relative;
    color: var(--dark40);
    width: 40px;
    margin: -0.25em 0 -0.5em;

    svg {
      width: 40px;

      path {
        fill: currentColor;
      }
    }
  }

  .label {
    position: relative;
    color: var(--dark50);
    font-size: 0.9em;
    margin-bottom: 0.1em;
  }

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition-delay: #{0.25s - ((0.05s * $i))- 0.05s};
    }
  }
}

.dark .option {
  background: rgba(#fff, 0.1);

  &:before {
    background: rgba(#fff, 0.5);
  }

  .icon {
    color: rgba(#fff, 0.4);
  }

  .label {
    color: rgba(#fff, 0.5);
  }
}

.adding .option {
  opacity: 1;
  transform: translate3d(0, 0, 0);

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition-delay: #{(0.05s * $i)- 0.05s};
    }
  }
}

//inline

.inline {
  &.container {
    display: block;
    position: absolute;
    bottom: -0.75em;
    height: 1.5em;
    width: calc(100% - var(--padding));
    opacity: 0;
    transition: opacity var(--speed) var(--ease);

    &:hover,
    &.adding {
      opacity: 1;
    }
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: var(--light);
    height: 1.5em;
    width: 1.5em;

    &:before {
      background: rgba(#550eff, 0.05);
    }

    i {
      color: var(--purple);
      width: 0.7em;
      height: 0.7em;
    }
  }

  .popup {
    position: absolute;
    z-index: 99;
    top: auto;
    left: 50%;
    bottom: calc(100% + 0.25em);
    transform: translate3d(-50%, 0, 0);
    flex-direction: row;
  }

  .item {
    margin-bottom: 0;
    transform: translate3d(0, -0.75em, 0);

    @at-root .adding .item {
      transform: translate3d(0, 0, 0);
    }

    &:not(:last-child) {
      margin-right: 0.2em;
    }
  }
}

.disabled .button {
  opacity: 0.4;
  background: var(--dark);
  pointer-events: none;
}
