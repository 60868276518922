@import 'ui/styles/utilities';

.container {
  font-size: 0.85em;
  align-items: center;
  width: 100%;
  cursor: pointer;
  max-width: 30em;

  & + & {
    // border-top: 1px solid var(--dark05);
    margin-top: calc(var(--padding) * 0.75);
    // padding-top: calc(var(--padding) * .75);
  }
}

.label {
  font-weight: 500;
  margin-bottom: calc(var(--padding) / 4);
}

.nolabel {
  margin-top: calc(var(--padding) * 0.25) !important;
}

.nolabel .label {
  display: none;
}

.half {
  width: calc(50% - 0.3em);
  max-width: calc(15em - 0.3em);
  display: inline-block;
  vertical-align: text-top;

  & + & {
    margin-left: 0.6em;
  }
}

.input {
  width: 100%;
  padding: 0.75em 0.9em 0.8em;
  font-family: inherit;
  font-size: inherit;
  border: none;
  appearance: none;

  &Container {
    border: 1px solid var(--dark10);
    border-radius: calc(var(--border-radius) / 1.5);
    width: 100%;
    overflow: hidden;
  }
}

.select .inputContainer {
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    right: 1em;
    top: 50%;
    width: 0.35em;
    height: 0.35em;
    border-top: 1px solid var(--dark20);
    border-right: 1px solid var(--dark20);
    pointer-events: none;
  }

  &:before {
    transform: translate3d(0, 25%, 0) rotate(135deg);
  }

  &:after {
    transform: translate3d(0, -125%, 0) rotate(-45deg);
  }
}

.help {
  color: var(--dark50);
  padding-top: 0.25em;
  margin-top: calc(var(--padding) / 8);
}

.value {
  margin-left: auto;
  color: var(--dark50);
}

.readonly {
  display: none;
  cursor: default;

  &:after {
    display: none;
  }

  .value {
    opacity: 0.35;
  }
}
