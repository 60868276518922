@import 'ui/styles/utilities';

.container {
  display: flex;
  height: 100%;
  --theme: var(--purple);
}

.header {
  padding: 0 0 calc(var(--padding) / 3);
  position: relative;
  display: flex;
  align-items: flex-start;

  .icon {
    position: relative;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: var(--dark30);
    margin: 0.1em 0.5em 0 -0.2em;
    flex-shrink: 0;
    display: none;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .label {
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--dark50);
    margin-bottom: 0.5em;
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 1.35rem;
    font-weight: 500;
    margin-bottom: -0.1em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chart {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.65em;
  font-weight: 500;
  flex-shrink: 1;
}

.y {
  position: absolute;
  left: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: calc(var(--padding) / 2) 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 7em;

  span {
    height: 1px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: var(--dark30);
    transition: opacity var(--speed) var(--ease);

    &:before {
      content: attr(data-label);
      font-family: var(--font-mono);
      font-feature-settings: var(--font-mono-settings);
      font-size: 0.95em;
      line-height: 1px;
      width: 5em;
      text-align: right;
      padding-right: 1em;
      font-feature-settings: 'tnum';
    }

    &:after {
      content: '';
      display: block;
      flex-grow: 1;
      border-top: 1px dashed var(--dark05);
      width: 100%;
    }
  }
}

.x {
  position: absolute;
  left: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: calc((var(--padding) / 2) - 0.5em) calc(var(--padding) / 2) 0 0;
  display: flex;
  flex-direction: row;
  padding-left: 4em;
  padding-right: 1em;
  padding-bottom: 6.5em;

  .item {
    padding: 0.5em 0;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    color: var(--dark30);
    transition: background-color var(--speed) var(--ease);
    border-radius: calc(var(--border-radius) / 2);

    > span {
      position: absolute;
      top: 100%;
      width: 100%;
      height: 6em;
      transition: opacity var(--speed) var(--ease);

      &:before {
        content: attr(data-label);
        font-family: var(--font-mono);
        font-feature-settings: var(--font-mono-settings);
        font-size: 0.95em;
        position: absolute;
        top: 1em;
        left: 50%;
        transform-origin: top left;
        transform: translate3d(12.5%, 0, 0) rotate(90deg);
      }
    }

    &--hidden > span {
      opacity: 0;
    }

    &--hidden:hover > span {
      opacity: 1;
    }

    &:hover {
      background: var(--dark05);
    }

    &:not(.item--hidden):has(+ .item--hidden:hover) > span {
      opacity: 0.5;
    }

    &--hidden:hover + .item:not(.item--hidden) > span {
      opacity: 0.5;
    }
  }
}

.bar {
  width: 100%;
  max-width: 5px;
  margin: 0 1px;
  display: flex;
  // transform: translate3d(0, 0, 0) rotate(0.0001deg);
  min-height: 1px;

  &[style*='height: 0%'],
  &:not([style*='%']) {
    opacity: 0;
  }

  div {
    background: var(--purple);
    width: 100%;
    height: 100%;
  }

  &.quality div {
    background: var(--green);
  }

  &.interactions div {
    background: var(--orange);
  }

  &.outbounds div {
    background: #0e9eff;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 30;
  transform: translate3d(0.65em, -50%, 0) rotate(0.1deg);
  // transition: transform var(--speed) var(--ease), opacity calc(var(--speed) * .75) var(--ease);
  opacity: 0;
  font-size: 0.75rem;
  font-weight: 500;
  background: #fff;
  padding: 0.75em;
  border-radius: var(--border-radius);
  color: var(--dark);
  filter: drop-shadow(0 2px 10px var(--dark05));
  pointer-events: none;
  margin-left: 0.4em;

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 50%;
    border-right: 0.4em solid #fff;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    margin-bottom: -0.4em;
  }

  &.right {
    margin-left: 0;
    margin-right: 0.4em;
    left: auto;
    right: 100%;

    &:after {
      content: '';
      position: absolute;
      right: auto;
      left: 100%;
      bottom: 50%;
      border-right: 0;
      border-left: 0.4em solid #fff;
    }
  }

  strong {
    font-size: 1rem;
    display: block;
    color: var(--dark);
    font-weight: 500;
    line-height: 0.9em;
  }

  em {
    font-size: 0.6rem;
    color: var(--dark30);
    line-height: 0.9em;
    display: block;
    margin-bottom: 0.25em;
    white-space: nowrap;
  }

  &Item {
    border-left: 4px solid var(--theme);
    padding-left: 0.5em;
    padding-top: 0.05em;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    &.quality {
      border-left-color: var(--green);
    }

    &.interactions {
      border-left-color: var(--orange);
    }

    &.outbounds {
      border-left-color: #0e9eff;
    }
  }
}

.x .item:hover .popup {
  opacity: 1;
  transform: translate3d(0, -50%, 0) rotate(0.1deg);
}

.small {
  .x .item:nth-child(2n) {
    span:before {
      display: none;
    }
  }
}

.stats {
  width: 25%;
  margin-left: calc(var(--padding) / 6);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.stat {
  height: 100%;
  display: flex;
  margin-right: calc(var(--padding) / 1.5);
}

.stats .line {
  height: 1px;
  background: var(--dark10);
  margin: calc(var(--padding) / 2);

  &:last-child {
    display: none;
  }
}
