.brand {
  text-align: center;
  margin: 0 auto;
  padding: var(--vpadding) var(--padding) var(--vpadding);

  @media (min-width: 900px) {
    padding: var(--vpadding) calc(var(--padding) * 1) calc(var(--vpadding));
    max-width: 28em;
    margin-top: 1em;
    left: calc((100vw - 28em) / 2);
    border-radius: var(--border-radius);
  }
}

.logo {
  max-width: 6.5em;
  max-height: 6.5em;
  margin: 0 auto var(--padding);

  @media (min-width: 900px) {
    max-width: 8.5em;
    max-height: 8.5em;
    margin: 0 auto calc(var(--padding) * 1.5);
  }

  img {
    position: relative;
    width: auto;
    height: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}

.name {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.3em;
  margin-bottom: 0.35em;
}

.text {
  p {
    font-size: 1.1em;
    line-height: 1.45em;

    @media (min-width: 900px) {
      font-size: 1.2em;
    }
  }
}

.brand h6 {
  margin-top: 1em;

  a {
    color: var(--color, var(--highlight));

    &:hover {
      text-decoration: underline;
      // @include underline(currentColor, .125em, 100%);
    }
  }
}
