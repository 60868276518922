.quote {
  max-width: calc(28rem + (var(--padding) * 2));
  padding: 0 var(--padding);

  blockquote {
    padding: 0 var(--padding);
  }

  p {
    font-size: 1.1em;
    line-height: 1.45em;

    @media (min-width: 900px) {
      font-size: 1.2em;
    }
  }
}

.slides {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  padding: var(--spadding);
  margin: 0;
  background: #f0f0f0;

  blockquote {
    padding: 0;
  }
}
