@import 'ui/styles/utilities';

.container {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: calc(var(--padding) / 4);
  font-size: var(--font-size);
  align-self: flex-start;
  font-weight: 500;
  line-height: 1.5em;

  input,
  textarea,
  select {
    background: var(--dark05);
    border-radius: calc(var(--border-radius));
    padding: 0.5em 0.9em;
  }

  hr {
    width: calc(100%);
    border: 0;
    margin: -0.25em 0 0;
    background: none;
    opacity: 1;
  }
}

.item {
  &.inline {
    display: flex;
    align-items: center;

    .label {
      width: clamp(8em, 45%, 13em);
      padding: 0;
    }

    .field {
      width: 100%;
    }
  }
}

.label {
  display: block;
  font-weight: 500;
  color: var(--dark50);
  padding-bottom: calc(var(--padding) / 6);
  letter-spacing: 0.01em;
  margin-top: -0.1em;
}

.field {
  input,
  textarea,
  select {
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;

    @include placeholder {
      color: var(--dark30);
    }
  }
}

.hidden {
  overflow: hidden;
  opacity: 0;
  width: 0;
  margin-right: 0 !important;
}

.help {
  color: var(--dark50);
  font-size: 0.85em;
  margin-top: 0.5em;
  line-height: 1.5em;

  a {
    color: var(--dark);

    &:hover {
      text-decoration: underline;
    }
  }
}
