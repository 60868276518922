@import 'ui/styles/utilities';

.field {
  position: relative;
  width: 100%;
  max-width: 100vh;
  margin: 0 auto;
  border-radius: var(--border-radius);
  aspect-ratio: 3/1;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0.05;
  }

  // @at-root &.input:before {
  //   display: none;
  // }

  // margin-bottom: calc(var(--padding)/1.5);

  input {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - (var(--padding) / 2));
    text-align: center;
    height: 2.2rem;
    transform: translate3d(-50%, -50%, 0);
    font-weight: 500;
    transition: all var(--speed) var(--ease);
    max-width: 22rem;
    padding: 0 0.9em;

    @include placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all var(--speed) var(--ease);
    opacity: 1 !important;
    padding: 0;
  }

  section {
    margin: 0;
    max-width: none;

    figure {
      max-height: calc(100vh - (var(--navbar-height) * 3));
      margin: 0 auto;
    }
  }
}

.input {
  aspect-ratio: auto;
}

.input label {
  pointer-events: none;
  transform: translate3d(0, calc(50% - 1.6rem), 0);
}

.input input {
  background: #fff;
  box-shadow: 0 0.2em 0.4em var(--dark10);
  color: var(--dark50);
  border-radius: calc(var(--border-radius) / 2);
  pointer-events: all;
}

.error input {
  color: var(--red);
}

.slides {
  height: 100%;
  display: flex;
  align-items: center;

  &.input label {
    transform: translate3d(0, 0, 0);
  }

  section {
    width: 100%;
  }
}
