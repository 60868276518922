@import 'ui/styles/utilities';

.container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 1.2em;
  width: 100%;
  border: 1px solid var(--dark05);
  transition: background var(--speed) var(--ease);
  --coverSize: calc(9.25rem / 20);

  &:hover {
    background-color: var(--dark0125);
  }
}

// .color {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: currentColor;
//   background-image: linear-gradient(to right, rgba(#fff, 0), #fff),
//     linear-gradient(to bottom, rgba(#fff, 0), #fff);
//   opacity: 0.02;
// }

.info {
  position: relative;
  padding: 1rem;
  display: flex;
  min-width: 0;
}

.logo {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
  border-radius: 0.5em;
  margin: -0.05em 0.8em -0.05em 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  transition: all var(--speed) var(--ease);
  transform: translate3d(0, 0, 0) rotate(0.02deg);
  border: 1px solid var(--dark05);

  img {
    position: absolute;
    inset: 6% !important;
    object-fit: contain;
    width: 88% !important;
    height: 88% !important;
  }

  span {
    display: block;
    position: relative;
    line-height: 2.4rem;
    width: 2.4rem;
    text-align: center;
    background: var(--dark05);
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--white);
  }
}

.meta {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
  flex-shrink: 1;

  span,
  em {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    min-width: 0;
  }

  .title {
    position: relative;
    width: 100%;
    font-weight: 500;
    font-size: 0.95em;
    height: 1em;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  em {
    font-size: 0.8em;
    opacity: 0.5;
    font-weight: 500;
    padding-top: 0.2em;
  }
}

.covers {
  position: relative;
  height: 10.25rem;
  display: flex;
  width: 100%;
  font-size: var(--coverSize);
  pointer-events: none;

  &-inside {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 1rem 1rem;
    gap: 0.45rem;
  }

  &-item {
    position: relative;
    transform-origin: left;
    display: flex;
    height: 100%;
    --cover-border-radius: 0.5rem;

    &.landscape {
      font-size: 1.3em;
    }

    &.responsive {
      aspect-ratio: 300/250;
      --cs__width: calc(9.25rem / 250 * 300);
      --cs__height: 9.25rem;
    }
  }

  &-item.empty {
    width: 15.3856em;
    flex-shrink: 0;
    border-radius: 1em;
    height: 20em;
    background: var(--dark50);

    &:nth-child(1) {
      opacity: 0.1;
    }

    &:nth-child(2) {
      opacity: 0.08;
    }

    &:nth-child(3) {
      opacity: 0.06;
    }

    &:nth-child(4) {
      opacity: 0.04;
    }
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
